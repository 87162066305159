import React from "react";
import { Link } from "react-router-dom";

export default function Web3Investment() {
    return (
        <div className="text-center pt-12 mx-4 md:mx-[56px]">
            <div className="flex justify-between mt-8 flex-wrap">
                <div className="max-w-[500px] text-left m-20 md:m-10">
                    <h2 className="text-[40px] mb-8">Long-Term Investment</h2>
                    <p className="text-[19px] text-[#CCCCCC]">Explore long-term investment opportunities to grow your wealth steadily and securely. From stocks and bonds to mutual funds, ETFs, REITs, and retirement accounts, we offer diverse options to suit your financial goals.</p>
                    <button className="my-12 get-started-btn">
                        <Link to="/" className="flex">
                            Find out more
                            <img src="/img/vector.svg" alt="arrow"/>
                        </Link>
                    </button>
                </div>
                <div>
                    <img src="img/fintech/coin1.png" alt="coin"/>
                </div>
            </div>
            <div className="flex justify-between mt-8 flex-wrap">
                <div>
                    <img src="img/fintech/coin2.png" alt="coinbag"/>
                </div>
                <div className="max-w-[500px] text-left m-20 md:m-10">
                    <h2 className="text-[40px] mb-8">Short-Term Investments</h2>
                    <p className="text-[19px] text-[#CCCCCC]">Explore short-term investment options tailored for quick returns and flexibility. From savings accounts and certificates of deposit (CDs) to money market funds and Treasury bills, we offer a range of choices to meet your immediate financial needs.</p>
                    <button className="my-12 get-started-btn">
                        <Link to="/" className="flex">
                            Find out more
                            <img src="/img/vector.svg" alt="arrow"/>
                        </Link>
                    </button>
                </div>
            </div>
            <div className="flex justify-between mt-8 flex-wrap">
                <div className="max-w-[800px] text-left m-20 md:m-10">
                    <h2 className="text-[40px] mb-8">Web3/Cryptocurrency Investments</h2>
                    <p className="text-[19px] text-[#CCCCCC]">Discover short-term investment opportunities in the exciting world of Web3 and cryptocurrency. From decentralized finance (DeFi) protocols to non-fungible tokens (NFTs) and emerging blockchain projects, explore the potential for rapid growth and innovation. Dive into this dynamic ecosystem and seize short-term opportunities for significant returns.</p>
                    <button className="my-12 get-started-btn">
                        <Link to="/" className="flex">
                            Find out more
                            <img src="/img/vector.svg" alt="arrow"/>
                        </Link>
                    </button>
                </div>
                <div>
                    <img src="img/fintech/coin3.png" alt="coin"/>
                </div>
            </div>
        </div>
    )
}