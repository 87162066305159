import React from "react";

// const data1 = [
//     "/img/ad1.svg",
//     "/img/ad2.svg",
//     "/img/ad3.svg",
//     "/img/ad4.svg",
//     "/img/ad5.svg",
//     "/img/ad6.svg",
// ];

// const data2 = [
//     "/img/ad6.svg",
//     "/img/ad7.svg",
//     "/img/ad8.svg",
//     "/img/ad9.svg",
//     "/img/ad10.svg",
//     "/img/ad11.svg",
//     "/img/ad12.svg",
// ]

// const data3 = [
//     "/img/ad13.svg",
//     "/img/ad14.svg",
//     "/img/ad15.svg",
//     "/img/ad16.svg",
//     "/img/ad17.svg",
//     "/img/ad18.svg",
// ]

const sampleNFTs = [
    {
        id: 1,
        source: "https://opensea.io/assets/ethereum/0x8e7b93896242299defc4860f3c093dc3ebf90a96/187",
        source_svg: "https://raw.seadn.io/files/a93c7ca3862256c459a6f9fc635a2f42.svg"
    },
    {
        id: 2,
        source: "https://opensea.io/assets/ethereum/0x8e7b93896242299defc4860f3c093dc3ebf90a96/232",
        source_svg: "https://raw.seadn.io/files/57191b7b6a92ff07f0f84095e34fba67.svg"
    },
    {
        id: 3,
        source: "https://opensea.io/assets/ethereum/0x8e7b93896242299defc4860f3c093dc3ebf90a96/125",
        source_svg: "https://raw.seadn.io/files/0386a9a2b375d44f53db4e88a5623a9a.svg"
    },
    {
        id: 4,
        source: "https://opensea.io/assets/arbitrum/0x47a91457a3a1f700097199fd63c039c4784384ab/100022970",
        source_svg: "https://raw.seadn.io/files/54406efeb4186925a29cecefb4b1bbc8.png"
    }
]

export default function LandingCreateNFT() {
    return (
        <div className="text-center relative overflow-hidden pt-36">
            <h2 className="text-[48px] max-w-[700px] mx-auto">Create your own NFT</h2>
            <p className="text-[23px] text-[#CCCCCC] max-w-[700px] mx-auto mt-4">Create, Sell and Collect Extraordinary Digital Art</p>
            <div className="flex mt-24 mx-4 mb-8">
                {sampleNFTs.map(item => (
                    <div key={item.id} className="card px-10 mx-8">
                        <a href={item.source} target="_blank">
                            <img src={item.source_svg} alt="Source from Opensea.io" width={180} height={180} />
                        </a>                    
                    </div>                        
                    ))}
            </div>
            <p className="text-[12px]">All NFT images are sourced from Opensea.io, Our own marketplace is launching soon...</p>
            <button className="connect-wallet-btn">Launching Soon</button>
        </div>
    )
}