import React, { useRef, useState } from 'react';
import emailjs, { send } from '@emailjs/browser';

export default function NotifyPage({productName}) {
    const form = useRef();
    const [senderEmail, setSenderEmail] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_kp81dyi', 'template_ywspldj', form.current, {
        publicKey: 'LqGV1XbpcjzIf3oi5',
      })
      .then(
        () => {
        //   console.log('SUCCESS!', senderEmail);
          setSenderEmail('');
        //   setContactMessage('');
          const thankYouMessage = document.querySelector('#thank-you-message');
          thankYouMessage.classList.add('show');
          setTimeout(() => thankYouMessage.classList.remove('show'), 4000);
        },
        (error) => {
        //   console.log('FAILED...', error.text);
          const noThankYouMessage = document.querySelector('#no-thank-you-message');
          noThankYouMessage.classList.add('show');
        },
      );
  };

  return (
    <div className="">                
        <form ref={form} onSubmit={sendEmail} className="">
            <div className="w-[300px] md:w-[400px] mx-auto relative">
                <input
                    id="sender-email"
                    name="reply-to"
                    type="email"
                    value={senderEmail}
                    onChange={e => setSenderEmail(e.target.value)}
                    autoComplete="email"
                    placeholder="Please enter your email address"
                    className="pl-8 pr-12 py-4 rounded-full w-[300px] md:w-[400px] text-[#A9A9A9] bg-[#24263D] outline-none"
                    />                
                <button type="submit" className="bg-[#7DA0FB] px-4 md:px-10 py-4 rounded-full absolute right-0 md:right-[-50px] focus:border-none focus:outline-none border-none outline-none active:bg-[#4645C2]">Notify Me</button>
            </div>
            <p className="text-[#A9A9A9] mt-8">Notify me when the page for {productName} is launched.</p>            
        </form>
        <div className='text-center pt-8'>
            <p id="thank-you-message">
                Thank you for contacting us. We will be in touch with you soon.
            </p>
        </div>
        <div className='text-center pt-8'>
            <p id="no-thank-you-message">
                Request is not submitted. Please try again or get in touch through our social channels.
            </p>
        </div>
    </div>
  )
}
