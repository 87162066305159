import React, { useState } from "react";
import { TransitionGroup, CSSTransition } from 'react-transition-group';

export default function LandingCompetition({items, state}) {
    
    const [active, setActive] = useState(state);
    const [direction, setDirection] = useState('');

    const generateItems = () => {
        let newitems = []
        let level
        for (let i = active - 2; i < active + 3; i++) {
            let index = i
            if (i < 0) {
                index = items.length + i
            } else if (i >= items.length) {
                index = i % items.length
            }
            level = active - i
            newitems.push(<CSSTransition key={index} timeout={500} classNames="item-transition"><Item img={items[index]} level={level} /></CSSTransition>)
        }
        return newitems
    }
    
    const moveLeft = () => {
        const newActive = active - 1;
        setActive(newActive < 0 ? items.length - 1 : newActive);
        setDirection('left');
    };
    
    const moveRight = () => {
        setActive((active+1) % items.length);
        setDirection('right');
    }
    
    return(
        <div className="h-[750px] overflow-x-hidden pt-20 mt-20">
            <div className="flex justify-between items-center">
                <div onClick={moveLeft} className="p-8"><img src="/img/buy/before.png" alt="before"/></div>
                <div className="text-center">
                    <p className="text-[48px]">e-Sports</p>
                    <p>Play in our Competitions & Earn Cryptocurrency in prizes</p>
                    <p>Announcing soon..</p>
                </div>
                <div onClick={moveRight} className="p-8"><img src="/img/buy/next.png" alt="next"/></div>
            </div>
            <div className="relative">
                <div id="carousel" className="noselect">
                    <TransitionGroup 
                        transitionname={direction}>
                        {generateItems()}
                    </TransitionGroup>
                </div>
            </div>                   
        </div>
    )
}

const Item = ({img, level}) => {
    const className = `item level${level}`;
    return(
        <div className={className}>
            <img className="rounded-lg" src={img.img} alt="img"/>
        </div>
    )
}