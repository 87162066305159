import React from 'react';

const ToggleSwitch = ({ enabled, onChange }) => {
  return (
    <label className="flex items-center cursor-pointer">
      {/* Hidden checkbox input */}
      <input type="checkbox" className="sr-only" checked={enabled} onChange={onChange} />
      {/* Line behind the switch */}
      <div className={`w-10 h-6 rounded-full shadow-inner ${enabled ? 'bg-[#644FE5]' : 'bg-gray-400'}`}></div>
      {/* Switch */}
      <div className={`ml-[-44px] transform transition-transform duration-200 ${enabled ? 'translate-x-6' : 'translate-x-0'} w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0`}></div>
    </label>
  );
};

export default ToggleSwitch;