import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// import BlockChain from './pages/BlockChain';
import Fintech from './pages/Fintech';
import ComingSoon from './pages/ComingSoon';
import Landing from './pages/Landing';
import Referral from './pages/Referral';
import Setting from './pages/Setting';
import Profile from './components/setting/Profile';
import SecuritySetting from './components/setting/SecuritySetting';
import NotificationSetting from './components/setting/NotificationSetting';
import Sigin from './pages/Signin';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import Error from './pages/Error';
import UnderConstruction from './pages/UnderConstruction';
import ComingSoon2 from './pages/ComingSoon2';
import UnderConstruction2 from './pages/UnderConstruction2';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Landing/>} />
          <Route path="/csoon" element={<ComingSoon/>} />
          <Route path="/csoongaming" element={<ComingSoon2/>} />
          <Route path='/invest' element={<Fintech/>} />
          <Route path='/underconstruct' element={<UnderConstruction/>} />
          <Route path='/underconstructa' element={<UnderConstruction2/>} />
          {/* <Route path='/buy' element={<BlockChain/>} /> */}
          <Route path='/referral' element={<Referral/>} />
          <Route path='/setting' element={<Setting/>}>
            <Route path='/setting/' element={<Profile/>} />
            <Route path='/setting/profile' element={<Profile/>} />
            <Route path='/setting/security' element={<SecuritySetting/>} />
            <Route path='/setting/notification' element={<NotificationSetting/>} />
          </Route>
          <Route path='/signin' element={<Sigin/>} />
          <Route path='/signup' element={<Signup/>} />
          <Route path='/dashboard' element={<Dashboard/>} />
          <Route path="*" element={<Error/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;