import React, { useState } from "react";

import { Link } from "react-router-dom";

const data = [
    {
        icon: "/img/setting/dashboard.png",
        title: "Dashboard",
        url: '/dashboard'
    },
    {
        icon: "/img/setting/wallet.png",
        title: "Wallet",
        url: '/wallet'
    },
    {
        icon: "/img/setting/nft.png",
        title: "NFT market",
        url: '/market'
    },
    {
        icon: "/img/setting/game.png",
        title: "Gaming",
        url: '/game'
    },
    {
        icon: "/img/setting/transaction.png",
        title: "Transaction",
        url: '/transaction'
    },
    {
        icon: "/img/setting/setting.png",
        title: "Settings",
        url: '/setting'
    },
    {
        icon: "/img/setting/news.png",
        title: "News",
        url: '/news'
    },
]

const subItems = {
    "Settings": [
        {
            icon: "/img/setting/personal.png",
            title: "Personal information",
            description: "Profile Setting",
            url: "/setting/profile"
        },
        {
            icon: "/img/setting/security.png",
            title: "Security",
            description: "For your account security",
            url: "/setting/security"
        },
        {
            icon: "/img/setting/currency.png",
            title: "Currency preferences",
            description: "Choose currency",
            url: "/setting/profile"
        },
        {
            icon: "/img/setting/notification.png",
            title: "Notifications",
            description: "Application notification settings",
            url: "/setting/notification"
        },
        {
            icon: "/img/setting/integration.png",
            title: "Integrations",
            description: "Access to APIs and side programs",
            url: "/setting/profile"
        }
    ]
}

export default function SettingSideBar() {

    const [tab, setTab] = useState("Settings");

    return (
        <>
            <div className="bg-[#171827] rounded-lg flex flex-col min-w-[209px] my-4" style={{ height: 'fit-content'}}>
                <div>
                    <div className="flex justify-center pt-10">
                        <div>
                            <img src="/img/avatar/user.png" alt="user" width="65px" height="65px"/>
                            <p className="py-4">Lily Smith</p>
                        </div>
                    </div>
                    <ul className="text-end pt-2">
                        { data.map((item, index) => (
                            <Link key={index} to={item.url}>
                                <li className={`flex items-center ml-8 pl-4 w-auto py-2 mt-2 mb-6 rounded-l-full cursor-pointer ${item.title === tab ? "bg-[#644FE5]": ""}`} onClick={() => setTab(item.title)}>
                                    <img src={item.icon} alt="icon" className="mr-2"/>
                                    <p>{item.title}</p>
                                </li>
                            </Link>
                        ))}
                    </ul>
                </div>
                <div className="my-12">
                    <div className="flex items-center ml-12">
                        <p>Logout</p>
                        <img src="/img/setting/logout.png" alt="logout" className="ml-2"/>
                    </div>
                </div>
            </div>
            <div className="w-[430px] m-4">
                {
                    subItems["Settings"]?.map((item, index) => (
                        <div className="bg-[#24263D] rounded-[20px] mb-4 py-6" key={index}>
                            <Link to={item.url}>
                                <div className="flex items-center ml-8">
                                    <img src={item.icon} alt="icon" className="m-4"/>
                                    <div className="py-2">
                                        <h6>{item.title}</h6>
                                        <p className="text-[#A9A9A9] text-[13px]">{item.description}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>   
                    ))
                }
            </div>
        </>        
    )
}