import React from "react";

// import NavBarWithAuth from "../components/NavBarWithAuth";
import Footer from "../components/Footer";
import LandingIntro from "../components/buy/LandingIntro";
import LandingSecure from "../components/buy/LandingSecure";
import LandingCreateNFT from "../components/buy/LandingCreateNFT";
import LandingCompetition from "../components/buy/LandingCompetition";
// import LandingFaq from "../components/buy/LandingFaq";
import NavBarLatest from "../components/NavBarLatest";

let items = [
    {
        img: "/img/games/dota2_social.jpg"
    },
    {
        img: "/img/games/lol_game.jpg"
    },
    {
        img: "/img/games/chess_game.jpg"
    }, 
    {
        img: "/img/games/cs_go.jpg"
    }, 
    {
        img: "/img/games/fortnite.jpg"
    },    
    // {
    //     img: "/img/buy/game2.png"
    // },
    // {
    //     img: "/img/buy/game3.png"
    // },
    // {
    //     img: "/img/buy/game4.png"
    // },
    // {
    //     img: "/img/buy/game5.png"
    // },
    // {
    //     img: "/img/buy/game1.png"
    // },
    // {
    //     img: "/img/buy/game2.png"
    // },
    // {
    //     img: "/img/buy/game3.png"
    // },
    // {
    //     img: "/img/buy/game4.png"
    // },
    // {
    //     img: "/img/buy/game5.png"
    // },
]

export default function Landing() {
    return (
        <div className="bg-[#090B17] text-white font-sans">
            <NavBarLatest />
            <LandingIntro/>
            <LandingSecure/>            
            <LandingCompetition items={items} state={0}/>               
            <LandingCreateNFT/>
            {/* <LandingFaq /> */}
            <Footer/>
        </div>
    )
}