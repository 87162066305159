import React, { useRef, useState } from 'react';
import emailjs, { send } from '@emailjs/browser';

export default function ContactPage() {
    const form = useRef();
    const [senderEmail, setSenderEmail] = useState(null);
    const [contactMessage, setContactMessage] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_kp81dyi', 'template_ab5rikk', form.current, {
        publicKey: 'LqGV1XbpcjzIf3oi5',
      })
      .then(
        () => {
        //   console.log('SUCCESS!', senderEmail);        
          setSenderEmail('');
        //   setPhoneNumber('');
          setContactMessage('');
          const thankYouMessage = document.querySelector('#thank-you-message');
          thankYouMessage.classList.add('show');
          setTimeout(() => thankYouMessage.classList.remove('show'), 4000);
        },
        (error) => {
        //   console.log('FAILED...', error.text);
          const noThankYouMessage = document.querySelector('#no-thank-you-message');
          noThankYouMessage.classList.add('show');
        },
      );
  };

  return (
    <div className="">                
        <form ref={form} onSubmit={sendEmail} className="">
            <h2 className="text-base font-semibold leading-7 text-purple">Contact Us</h2>
            <div className="sm:col-span-2 m-4 p-2">
                <label htmlFor="sender-email" className="block text-sm font-medium leading-6">
                    Email address
                </label>
                <div className="mt-2">
                    <input
                    id="sender-email"
                    name="reply-to"
                    type="email"
                    value={senderEmail}
                    onChange={e => setSenderEmail(e.target.value)}
                    autoComplete="email"
                    className="block w-[75%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
            </div>
            <div className="sm:col-span-2 m-4 p-2">
                <label htmlFor="request-type" className="block text-sm font-medium leading-6">
                    Request Type
                </label>
                <div className="mt-2">
                    <select
                    id="request-type"
                    name="contact-request-type"                        
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                        <option>Subscribe</option>                    
                        <option>General Enquiry</option>
                    </select>
                </div>
            </div>  
            <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="col-span-full m-4 p-2">
                    <label htmlFor="requestInfo" className="block text-sm font-medium leading-6">
                        Message (Optional)
                    </label>
                    <div className="mt-2">
                        <textarea
                        id="requestInfo"
                        name="message"
                        rows={3}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={''}
                        value={contactMessage}
                        onChange={e => setContactMessage(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="mt-6 flex items-center justify-center gap-x-6">
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Submit
                </button>
            </div>            
        </form>
        <div className='text-center pt-8'>
            <p id="thank-you-message">
                Thank you for contacting us. We will be in touch with you very soon.
            </p>
        </div>
        <div className='text-center pt-8'>
            <p id="no-thank-you-message">
                Request is not submitted. Please try again or get in touch through our social channels.
            </p>
        </div>
    </div>
  )
}
