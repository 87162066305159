import React from "react";

const data1 = [
    "/img/ad1.svg",
    "/img/ad2.svg",
    "/img/ad3.svg",
    "/img/ad4.svg",
    "/img/ad5.svg",
    "/img/ad6.svg",
];

const data2 = [
    "/img/ad6.svg",
    "/img/ad7.svg",
    "/img/ad8.svg",
    "/img/ad9.svg",
    "/img/ad10.svg",
    "/img/ad11.svg",
    "/img/ad12.svg",
]

const data3 = [
    "/img/ad13.svg",
    "/img/ad14.svg",
    "/img/ad15.svg",
    "/img/ad16.svg",
    "/img/ad17.svg",
    "/img/ad18.svg",
]

export default function Web3Exp() {
    return (
        <div className="text-center relative overflow-hidden pt-36">
            <h2 className="text-[48px] max-w-[700px] mx-auto">Passive Pools</h2>
            <p className="text-[23px] text-[#CCCCCC] max-w-[700px] mx-auto mt-4">Passive Pools is an invest & earn mobile app that<br/> allows you to buy investment products</p>
            <div className="mt-24 mx-4">
                <p className="mb-12 text-[#CCCCCC]">Trusted by:</p>
                <div className="flex ml-[-100px]">
                    { data1.map(item => <div key={item} className="bg-white rounded-md py-2 mx-10 min-w-[255px] flex justify-center"><img src={item} alt="ad"/></div>)}
                </div>
                <div className="flex ml-[-200px] mt-8">
                    { data2.map(item => <div key={item} className="bg-white rounded-md py-2 mx-10 min-w-[255px] flex justify-center"><img src={item} alt="ad"/></div>)}
                </div>
                <div className="flex mt-8">
                    { data3.map(item => <div key={item} className="bg-white rounded-md py-2 mx-10 min-w-[255px] flex justify-center"><img src={item} alt="ad"/></div>)}
                </div>
            </div>
            <button className="connect-wallet-btn">Browse ecosystem</button>
        </div>
    )
}