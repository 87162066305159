import React from "react";

import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const links = [
    {
        title: "Home",
        url: "/"
    },
    {
        title: "Invest 2 Earn",
        url: "/underconstruct"
    },    
    {
        title: "Play 2 Earn",
        url: "/csoongaming"
    },
    {
        title: "NFT marketplace",
        url: "/csoon"
    },
    {
        title: "About Us",
        url: "/underconstructa"
    }
]

export default function NavBarLatest() {
    const { pathname } = useLocation();

    return (
        <div className="flex flex-cols-3 items-center justify-between">
            <div className="flex mx-4 md:mx-[56px] my-[54px]">
            <   Link to="/">
                    <div className="flex justify-center">
                        <img src="/img/logo.svg" alt="logo" className="mr-2"/>
                        <span className="text-[28px]">CHAKODY</span>
                    </div>
                </Link> 
                {/* <img src="/img/logo.svg" alt="logo" className="mr-2"/>
                <span className="text-[28px]">CHAKODY</span> */}
            </div>
            <ul className="hidden lg:flex items-center">
                { links.map((item) => (
                    <li key={item.url} className={`mx-5 hover:text-[#9D9DFF] cursor-pointer ${item.url === pathname && "text-[#9D9DFF]"}`}>
                        <Link to={item.url}>{item.title}</Link>
                    </li>
                ))}
            </ul>
            <div className="flex items-center auth md:mr-8">
                <Link to="/signin">
                    <button className="active px-[10px] md:px-[30px]">
                        Sign In
                    </button>
                </Link>
                {/* <Link to="/signin">
                    <button className="active px-[10px] md:px-[30px]">
                        Connect Wallet
                    </button>
                </Link> */}
            </div>
        </div>
    )
}