import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "../components/common/Modal";
import ContactPage from "./common/ContactPage";

export default function Footer() {
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const toggleShareModal = () => setShareModalOpen(!shareModalOpen);
    return (
        <div className="px-4 md:px-[90px] mt-[50px] footer">
            <div className="flex justify-between flex-wrap">
                <div className="flex pt-12 items-start">
                    <div className="flex">
                        <img src="/img/logo.svg" alt="logo" className="mr-2"/>
                        <span className="text-[28px]">CHAKODY</span>                        
                    </div>
                    {/* <div className="ml-16">
                        <div className="flex mb-8">
                            <img src="/img/icon1.svg" alt="language" className="mr-2"/>
                            <span className="text-[13px]">English</span>
                        </div>
                        <div className="flex">
                            <img src="/img/icon2.svg" alt="currency" className="mr-2"/>
                            <span className="text-[13px]">USD-$</span>
                        </div>
                    </div> */}
                </div>
                <div className="pt-12">
                    <div className="flex flex-wrap">
                        {/* <ul>
                            <li><Link to="">My Account</Link></li>
                            <li><Link to="">Profile</Link></li>
                            <li><Link to="">Favorites</Link></li>
                            <li><Link to="">Settings</Link></li>
                        </ul> */}
                        {/* <ul>
                            <li><Link to="">Company</Link></li>
                            <li><Link to="">About</Link></li>
                            <li><Link to="">News</Link></li>
                            <li><Link to="">Terms & Privacy</Link></li>
                            <li><Link to="">Blog</Link></li>
                        </ul> */}
                        <ul>
                            <li><Link to="">Products</Link></li>
                            {/* <li><Link to="">Blockchain Security</Link></li> */}
                            <li><Link to="/invest">Invest 2 Earn</Link></li>
                            {/* <li><Link to="">Web3</Link></li> */}
                            <li><Link to="/csoongaming">Play 2 Earn</Link></li>
                            <li><Link to="/csoon">NFT Marketplace</Link></li>
                            {/* <li><Link to="">Exchange</Link></li>
                            <li><Link to="">Buy Crypto</Link></li>
                            <li><Link to="">Leveraged Tokens</Link></li>
                            <li><Link to="">TradingView</Link></li>
                            <li><Link to="">Pay</Link></li>
                            <li><Link to="">Live</Link></li> */}
                        </ul>
                        {/* <ul>
                            <li><Link to="">Learn</Link></li>
                            <li><Link to="">Learn & Earn</Link></li>
                            <li><Link to="">Browse Crypto Prices</Link></li>
                            <li><Link to="">Bitcoin Price</Link></li>
                            <li><Link to="">Ethereum Price</Link></li>
                            <li><Link to="">Browse Crypto Price Predictions</Link></li>
                            <li><Link to="">Bitcoin Price Prediction</Link></li>
                            <li><Link to="">Ethereum Price Prediction</Link></li>
                            <li><Link to="">Buy Bitcoin</Link></li>
                            <li><Link to="">Buy Ripple</Link></li>
                            <li><Link to="">Buy Ethereum</Link></li>
                            <li><Link to="">Buy Tradable Altcoins</Link></li>
                        </ul> */}
                        <div>
                            {/* <ul>
                                <li><Link to="">Service</Link></li>
                                <li><Link to="">Referral</Link></li>
                                <li><Link to="">OTC Trading</Link></li>
                                <li><Link to="">Historical Market Data</Link></li>
                                <li><Link to="">Proof of Reserves</Link></li>
                            </ul> */}
                            <ul className="mt-0">
                                <li><Link to="">Support</Link></li>
                                {/* <li><Link to="">24/7 Chat Support</Link></li> */}
                                {/* <li><Link to="">Subscribe</Link></li> */}
                                <li><button onClick={toggleShareModal}>Subscribe</button></li>
                                {/* <li><Link to="">APIs</Link></li>
                                <li><Link to="">Chakody Verify</Link></li>
                                <li><Link to="">Trading Rules</Link></li> */}
                            </ul>
                            <Modal isOpen={shareModalOpen} toggleModal={toggleShareModal}>
                                <ContactPage />
                            </Modal>
                            {/* <Modal isOpen={shareModalOpen} toggleModal={toggleShareModal}>
                                <img src="/img/referral/Bitcoin_3D.png" alt="bitcoin" className="mx-auto"/>
                                <h2 className="text-[23px] font-bold">SHARE TO GET MORE</h2>
                                <p className="mt-2 text-[#9D9DFF] mb-4 max-w-[400px] text-white mx-auto">Share it on your social media to get a <br/> <span className="text-[#9D9DFF]">0.003 BTC</span> boost now!</p>
                                <button className="bg-[#644FE5] px-16 py-4 rounded-full my-4">Share</button>
                            </Modal> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex">
                <span>Follow & Share</span>
                <div>
                    <a href="https://www.linkedin.com/company/chakodytech" target="_blank">
                        <img src="/img/referral/linkedin.png" alt="notification" className="px-4"/>
                    </a>
                </div>                
            </div>
            <div className="bg-[#2B3139] h-[1px] w-[70%] mx-auto mt-12 mb-6"></div>
            <div className="text-center pb-10">
                Chakody L.L.C© 2024                 
            </div>
        </div>
    )
}