import React from "react";

export default function Profile() {
    return (
        <div>
            <h4 className="pt-4">My Profile</h4>
            <dv className="flex justify-center relative">
                <img src="/img/avatar/user.png" alt="user" width="82px" height="82px"/>
                <button className="absolute right-[10px] bottom-[10px] border-2 border-[#644FE5] px-10 py-1 rounded-full">Edit</button>
            </dv>
            <div className="my-4">
                <h5 className="mb-4">Personal Informations</h5>
                <div className="flex flex-wrap justify-between">
                    <div className="flex flex-col w-[43%]">
                        <label className="text-[#A9A9A9]">Legal name</label>
                        <input value="Lucy Smith" className="my-2 py-2 px-4 bg-[#151623] rounded-full outline-none"/>
                    </div>
                    <div className="flex flex-col w-[43%]">
                        <label className="text-[#A9A9A9]">Date of birth</label>
                        <input value="10.01.2000" className="my-2 py-2 px-4 bg-[#151623] rounded-full outline-none"/>
                    </div>
                    <div className="flex flex-col w-[43%]">
                        <label className="text-[#A9A9A9]">Address</label>
                        <input value="Argentina, Buenos Ayres" className="my-2 py-2 px-4 bg-[#151623] rounded-full outline-none"/>
                    </div>
                    <div className="flex flex-col w-[43%]">
                        <label className="text-[#A9A9A9]">Identification documents</label>
                        <input value="AP********45" className="my-2 py-2 px-4 bg-[#151623] rounded-full outline-none"/>
                    </div>
                    <div className="flex flex-col w-[43%]">
                        <label className="text-[#A9A9A9]">Email address</label>
                        <input value="luc***@gmail.com" className="my-2 py-2 px-4 bg-[#151623] rounded-full outline-none"/>
                    </div>
                </div>
            </div>
            <div className="my-4">
                <h5 className="mb-4">Account limits</h5>
                <div className="flex flex-wrap justify-between">
                    <div className="flex flex-col w-[43%]">
                        <label className="text-[#A9A9A9]">Fiat deposit & withdrawal limits</label>
                        <input value="50K USD daily" className="my-2 py-2 px-4 bg-[#151623] rounded-full outline-none"/>
                    </div>
                    <div className="flex flex-col w-[43%]">
                        <label className="text-[#A9A9A9]">Crypto deposit limit</label>
                        <input value="Unlimited" className="my-2 py-2 px-4 bg-[#151623] rounded-full outline-none"/>
                    </div>
                    <div className="flex flex-col w-[43%]">
                        <label className="text-[#A9A9A9]">P2P transaction limits</label>
                        <input value="Unlimited" className="my-2 py-2 px-4 bg-[#151623] rounded-full outline-none"/>
                    </div>
                    <div className="flex flex-col w-[43%]">
                        <label className="text-[#A9A9A9]">Crypto withdrawal limit</label>
                        <input value="8M USDT daily" className="my-2 py-2 px-4 bg-[#151623] rounded-full outline-none"/>
                    </div>
                </div>
                
            </div>
            <div className="flex justify-between py-8 flex-wrap">
                <div>
                    <p className="text-[#9F9F9F]">Account created at</p>
                    <p>Tuesday - 20 August 2024</p>
                </div>
                <div>
                    <button className="px-4 py-2 mx-2 border-2 border-[#644FE5] rounded-full hover:bg-[#644FE5]">Cancel</button>
                    <button className="px-4 py-2 mx-2 border-2 border-[#644FE5] rounded-full bg-[#644FE5]">Save changes</button>
                </div>
            </div>
        </div>
    )
}