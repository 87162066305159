import React from "react";
import { Link } from "react-router-dom";

import NavBarLatest from "../components/NavBarLatest";

export default function Error() {
    return (
        <div className="bg-[#090B17] h-screen text-white relative background-with-blur font-sans relative overflow-y-hidden">
            <NavBarLatest/>
            <div className="flex flex-col justify-center text-center">
                <div className="mx-auto">
                    <img src="/img/error.png" alt="error"/>
                </div>
                <h5 className="mt-8 text-[23px] max-w-[500px] mx-auto z-10">We seem to have run into a bit of a problem with this page, <span className="text-[#8888FF]">kindly reload.</span></h5>
                <button className="reload-btn w-[150px] mx-auto mt-5 z-10">
                    <Link to="/" className="flex justify-center items-center">
                        Reload
                        <img src="/img/reload_icon.png" alt="reload" className="ml-2 h-[15px]"/>
                    </Link>
                </button>
                <img src="/img/circularCoil.png" alt="circularCoil" className="absolute right-[6%] bottom-[-120px]"/>
            </div>
            
        </div>
    )
}