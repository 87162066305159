import React, { useState } from "react";

import { Link } from "react-router-dom";
import { ChartDonut, ChartThemeColor, ChartLabel } from '@patternfly/react-charts';

import ReferralNav from "../components/referral/ReferralNav";

const data = [
    {
        icon: "/img/setting/dashboard.png",
        title: "Dashboard",
        url: '/dashboard'
    },
    {
        icon: "/img/setting/wallet.png",
        title: "Wallet",
        url: '/wallet'
    },
    {
        icon: "/img/setting/nft.png",
        title: "NFT market",
        url: '/market'
    },
    {
        icon: "/img/setting/game.png",
        title: "Gaming",
        url: '/game'
    },
    {
        icon: "/img/setting/transaction.png",
        title: "Transaction",
        url: '/transaction'
    },
    {
        icon: "/img/setting/setting.png",
        title: "Settings",
        url: '/setting'
    },
    {
        icon: "/img/setting/news.png",
        title: "News",
        url: '/news'
    },
]

const tableData = [
    {
        icon: "/img/dashboard/btc.png",
        amount: 604,
        description: "Withdraw USDT",
        id: 14525156,
        date: "Mar 21, 2023",
        state: 0,
        fee: "0.12000"
    },
    {
        icon: "/img/dashboard/eth.png",
        amount: 604,
        description: "Withdraw BTC",
        id: 14525156,
        date: "Mar 21, 2023",
        state: 2,
        fee: "0.12000"
    },
    {
        icon: "/img/dashboard/usdt.png",
        amount: 604,
        description: "Withdraw BTC",
        id: 14525156,
        date: "Mar 21, 2023",
        state: 1,
        fee: "0.12000"
    },
    {
        icon: "/img/dashboard/eth.png",
        amount: 604,
        description: "Withdraw USDT",
        id: 14525156,
        date: "Mar 21, 2023",
        state: 0,
        fee: "0.12000"
    },
]

const MainDonutChart = () => (
    <svg width={200} height={200}>
        <circle cx={100} cy={100} r={74} fill="#16171A" />
        <ChartDonut
            ariaDesc="Average number of pets"
            ariaTitle="Donut chart example"
            constrainToVisibleArea
            data={[{ x: 'Cats', y: 35 }, { x: 'Dogs', y: 55 }, { x: 'Birds', y: 25 }]}
            labels={({ datum }) => `${datum.x}: ${datum.y}%`}
            legendOrientation="vertical"
            legendPosition="bottom"
            name="mainChart"
            padding={{
            bottom: 20,
            left: 20,
            right: 20, // Adjusted to accommodate legend
            top: -10
            }}
            subTitle="0.0140 BTC"
            subTitleComponent={<ChartLabel y={125} />}
            title="$1560.60"
            themeColor={ChartThemeColor.multiOrdered}
            width={200}
            colorScale={[
                '#4846C4',            
                '#F5A623',
                '#FA49C1',
            ]}
            radius={95}
            innerRadius={83}
            standalone={false}
            cornerRadius={20}
        />
    </svg>
)

const SubDonutChart = ({ percent, title1, color}) => (
    <svg width={70} height={60}>
        <circle cx={30} cy={30} r={24} fill="#16171A" />
        <ChartDonut
            ariaDesc="Average number of pets"
            ariaTitle="Donut chart example"
            constrainToVisibleArea
            data={[{ x: 'Cats', y: percent }, { x: 'Dogs', y: 100-percent }]}
            labels={({ datum }) => `${datum.x}: ${datum.y}%`}
            legendOrientation="vertical"
            legendPosition="bottom"
            name="subChart1"
            padding={{
                bottom: 10,
                left: 10,
                right: 10, // Adjusted to accommodate legend
                top: 10
            }}
            title={title1}
            titleComponent={<ChartLabel y={25} />}
            themeColor={ChartThemeColor.multiOrdered}
            width={60}
            height={60}
            colorScale={[
                color,            
                '#24263D'
            ]}
            radius={30}
            innerRadius={26}
            standalone={false}
            cornerRadius={5}
        />
    </svg>    
)

export default function Dashboard() {

    const [tab, setTab] = useState("Dashboard");

    return (
        <div className="relative bg-[#090B17] 2xl:h-[100vh] text-white">
            <ReferralNav/>
            <div className="mx-[20px] xl:mx-[54px] flex flex-row flex-wrap">
                <div className="bg-[#171827] rounded-lg flex flex-col w-[160px] xl:w-[209px] my-2" style={{ height: 'fit-content'}}>
                    <div>
                        <div className="flex justify-center pt-10">
                            <div>
                                <img src="/img/avatar/user.png" alt="user" width="65px" height="65px"/>
                                <p className="py-4">Lily Smith</p>
                            </div>
                        </div>
                        <ul className="text-end pt-2">
                            { data.map((item, index) => (
                                <Link to={item.url} key={index}>
                                    <li className={`flex items-center ml-8 pl-4 w-auto py-2 mt-2 mb-6 rounded-l-full cursor-pointer ${item.title === tab ? "bg-[#644FE5]": ""}`} onClick={() => setTab(item.title)}>
                                        <img src={item.icon} alt="icon" className="mr-2"/>
                                        <p>{item.title}</p>
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                    <div className="my-12">
                        <div className="flex items-center ml-12">
                            <p>Logout</p>
                            <img src="/img/setting/logout.png" alt="logout" className="ml-2"/>
                        </div>
                    </div>
                </div>
                <div className="w-[58%] xl:w-[64%] 2xl:w-[70%] my-2">
                    <div className="flex flex-row justify-between">
                        <div className="bg-[#24263D] rounded-[20px] px-2 xl:px-6 py-2 mx-4 w-[25%]">
                            <h2 className="text-[20px] xl:text-[23px] font-bold my-1">1,088.3 USD</h2>
                            <div className="flex items-center">
                                <div className="bg-[#4645C2] h-[16px] w-[16px] flex justify-center items-center mr-2 rounded-md">
                                    <div className="w-[6px] h-[6px] rounded-full bg-white"></div>
                                </div>
                                <p className="text-[#A9A9A9]">Main Account</p>
                            </div>
                            <div className="w-full bg-[#090B17] rounded-full h-1.5 dark:bg-gray-700 mt-2">
                                <div
                                    className="bg-[#644FE5] h-1.5 rounded-full"
                                    style={{ width: `${40}%` }}
                                ></div>
                            </div>
                        </div>
                        <div className="bg-[#24263D] rounded-[20px] px-2 xl:px-6 py-2 mx-2 w-[25%]">
                            <h2 className="text-[20px] xl:text-[23px] font-bold my-1">1,088.3 USD</h2>
                            <div className="flex items-center">
                                <div className="bg-[#9D9DFF] h-[16px] w-[16px] flex justify-center items-center mr-2 rounded-md">
                                    <div className="w-[6px] h-[6px] rounded-full bg-white"></div>
                                </div>
                                <p className="text-[#A9A9A9]">Trading Account</p>
                            </div>
                            <div className="w-full bg-[#090B17] rounded-full h-1.5 dark:bg-gray-700 mt-2">
                                <div
                                    className="bg-[#9D9DFF] h-1.5 rounded-full"
                                    style={{ width: `${40}%` }}
                                ></div>
                            </div>
                        </div>
                        <div className="bg-[#24263D] rounded-[20px] px-2 xl:px-6 py-2 mx-2 w-[25%]">
                            <h2 className="text-[20px] xl:text-[23px] font-bold my-1">1,088.3 USD</h2>
                            <div className="flex items-center">
                                <div className="bg-[#F7931A] h-[16px] w-[16px] flex justify-center items-center mr-2 rounded-md">
                                    <div className="w-[6px] h-[6px] rounded-full bg-white"></div>
                                </div>
                                <p className="text-[#A9A9A9]">Margin Account</p>
                            </div>
                            <div className="w-full bg-[#090B17] rounded-full h-1.5 dark:bg-gray-700 mt-2">
                                <div
                                    className="bg-[#F7931A] h-1.5 rounded-full"
                                    style={{ width: `${40}%` }}
                                ></div>
                            </div>
                        </div>
                        <div className="bg-[#24263D] rounded-[20px] px-2 xl:px-6 py-2 mx-4 w-[25%]">
                            <h2 className="text-[20px] xl:text-[23px] font-bold my-1">1,088.3 USD</h2>
                            <div className="flex items-center">
                                <div className="bg-[#F6475D] h-[16px] w-[16px] flex justify-center items-center mr-2 rounded-md">
                                    <div className="w-[6px] h-[6px] rounded-full bg-white"></div>
                                </div>
                                <p className="text-[#A9A9A9]">Futures Account</p>
                            </div>
                            <div className="w-full bg-[#090B17] rounded-full h-1.5 dark:bg-gray-700 mt-2">
                                <div
                                    className="bg-[#F6475D] h-1.5 rounded-full"
                                    style={{ width: `${40}%` }}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className="px-8 py-4 bg-[#24263D] rounded-[20px] mx-4 my-4">
                        <h3>Portfolio stats</h3>
                        <img src="/img/dashboard/temp.png" alt="chart" className="w-[100%] h-[230px]"/>
                    </div>
                    <div className="px-8 py-4 bg-[#24263D] rounded-[20px] mx-4">
                        <div className="flex justify-between flex-wrap">
                            <h2>Recent activities</h2>
                            <div className="flex flex-wrap">
                                <div className="bg-[#151623] rounded-full flex items-center py-2 w-[235px] 2xl:w-[300px]">
                                    <img src="/img/referral/searchIcon.svg" alt="search" className="pl-6"/>
                                    <input placeholder="Search in dashboard..." className="bg-[#151623] rounded-full pl-4 outline-none lg:w-[300px]"/>
                                </div>
                                <div className="bg-[#151623] rounded-full flex items-center py-1 w-[150px] xl:w-[200px] px-4 xl:px-8 ml-2">
                                    <select className="bg-[#151623] rounded-full flex items-center py-1 w-[160px] outline-none">
                                        <option>Last 30 days</option>
                                        <option>Last 7 days</option>
                                    </select>
                                </div>
                                <div className="bg-[#151623] rounded-full flex items-center py-1 w-[180px] 2xl:w-[200px] px-2 xl:px-8 ml-2 mt-2 xl:mt-0">
                                    <select className="bg-[#151623] rounded-full flex items-center py-1 w-[200px] outline-none">
                                        <option>September 2023</option>
                                        <option>August 2023</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <table className="w-[100%] text-center leading-[3rem]">
                            <thead >
                                <tr>
                                    <td className="text-start">Coin</td>
                                    <td className="text-start pl-8">Transaction</td>
                                    <td>ID</td>
                                    <td>Date</td>
                                    <td>Status</td>
                                    <td>Fees</td>
                                </tr>
                                <tr className="px-8">
                                    <td colSpan={6} className="bg-[#636267]"></td>
                                </tr>
                            </thead>
                            <tbody className="leading-[3rem]">
                                {
                                    tableData.map((item, index) => (
                                        <tr key={index} className="py-4">
                                            <td>
                                                <img src={item.icon} alt="btc"/>
                                            </td>
                                            <td>
                                                <div className="text-start pl-8 leading-6">
                                                    <p>${item.amount}</p>
                                                    <p className="text-[#9F9F9F]">{item.description}</p>
                                                </div>
                                            </td>
                                            <td>
                                                #{item.id}
                                            </td>
                                            <td>
                                                {item.date}
                                            </td>
                                            <td>
                                                { item.state === 0 && <p className="text-[#00FFA3]">Completed</p>}
                                                { item.state === 1 && <p className="text-[#FF897A]">Pending</p>}
                                                { item.state === 2 && <p className="text-[#F6475D]">Rejected</p>}
                                            </td>
                                            <td>
                                                {item.fee} BTC
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="w-[250px] xl:w-[270px] my-2">
                    <div className="py-4 bg-[#24263D] rounded-[20px] ml-2 h-[405px]">
                        <p className="px-8">Balance</p>
                        <div className="px-8">
                            <MainDonutChart/>
                        </div>
                        <div className="flex flex-wrap pl-8">
                            <div className="flex items-center mb-2">
                                <SubDonutChart percent={40} title1="50%" color="#F5A623"/>
                                <div className="text-[11px]">
                                    <p className="text-[#9F9F9F]">BTC</p>
                                    <p>$1920</p>
                                </div>
                            </div>
                            <div className="flex items-center mb-2">
                                <SubDonutChart percent={35} title1="35%" color="#4846C4"/>
                                <div className="text-[11px]">
                                    <p className="text-[#9F9F9F]">BTC</p>
                                    <p>$1920</p>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <SubDonutChart percent={10} title1="10%" color="#FA49C1"/>
                                <div className="text-[11px]">
                                    <p className="text-[#9F9F9F]">BTC</p>
                                    <p>$1920</p>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <SubDonutChart percent={5} title1="5%" color="#00FFA3"/>
                                <div className="text-[11px]">
                                    <p className="text-[#9F9F9F]">BTC</p>
                                    <p>$1920</p>
                                </div>
                            </div>
                        </div>
                        <p className="text-center cursor-pointer text-[#9F9F9F]">{`View all >`}</p>
                    </div>
                    <div className="px-8 py-4 bg-[#24263D] rounded-[20px] ml-2 mt-4 h-[34vh]">
                        <p className="mb-6">Quick exchange</p>
                        <div className="bg-[#151623] rounded-[20px] px-4 py-2">
                            <div className="flex justify-between">
                                <p>I have:</p>
                                <p>0.12000 BTC</p>
                            </div>
                            <div className="flex justify-between mt-1">
                                <div className="flex items-center">
                                    <img src="/img/dashboard/btc.png" alt="btc" className="w-[24px] h-[24px]"/>
                                    <p className="ml-2">BTC</p>
                                </div>
                                <p>0.01</p>
                            </div>
                        </div>
                        <div>
                            <img src="/img/dashboard/exchange.png" alt="exchange" className="mx-auto mt-[-10px] mb-[-10px]"/>
                        </div>
                        <div className="bg-[#151623] rounded-[20px] px-4 py-2">
                            <div className="flex justify-between">
                                <p>I have:</p>
                                <p>0.12000 BTC</p>
                            </div>
                            <div className="flex justify-between mt-1">
                                <div className="flex items-center">
                                    <img src="/img/dashboard/eth.png" alt="eth" className="w-[24px] h-[24px]"/>
                                    <p className="ml-2">USDT</p>
                                </div>
                                <p>$604</p>
                            </div>
                        </div>
                        <button className="bg-[#644FE5] w-[100%] py-2 rounded-full mt-8">Exchange</button>
                    </div>
                </div>
            </div>
        </div>
    )
}