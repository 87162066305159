import React from "react";
import { Link } from "react-router-dom";

const data = [
    {
        icon: '/img/dropdownIcon/dashboard.svg',
        title: 'Dashboard',
        url: '/dashboard'
    },
    {
        icon: '/img/dropdownIcon/wishlist.svg',
        title: 'Wishlist',
        url: '/'
    },
    {
        icon: '/img/dropdownIcon/referral.svg',
        title: 'Referral',
        url: '/referral'
    },
    {
        icon: '/img/dropdownIcon/reward.svg',
        title: 'Rewards Hub',
        url: '/reward'
    },
    {
        icon: '/img/dropdownIcon/settings.svg',
        title: 'All Settings',
        url: '/setting'
    },
]

export default function SettingDropDown() {
    return (
        <div className="p-4 px-8 bg-gradient-to-r from-[#6C73BC] to-[#574FAE] absolute right-0 top-[40px] w-[250px] rounded-[20px] z-50 font-bold">
            <p className="text-start">Setting</p>
            <ul>
                {
                    data.map((item, index) => (
                        <Link key={index} to={item.url}>
                            <li className="flex py-3">
                                <img src={item.icon} alt="icon" className="mr-4"/>
                                <p className="text-bold">{item.title}</p>
                            </li>
                        </Link>
                    ))
                }
            </ul>
            <Link to="/">
                <div className="flex mt-6">
                    <p>Log out</p>
                    <img src="/img/dropdownIcon/logout.svg" alt="logout" className="ml-4"/>
                </div>
            </Link>
        </div>
    )
}