import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import { CountrySelector, usePhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

export default function Signup() {

    const [step, setStep] = useState(0);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordType, setPasswordType] = useState('password');
    const [selectedCountry, setSelectedCountry] = useState("");
    const [phone, setPhone] = useState('');

    const phoneInput = usePhoneInput({
        defaultCountry: "us",
        phone,
        onChange: (data) => {
          setPhone(data.phone);
        }
      });

    const togglePasswordType = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
        } else {
            setPasswordType('password');
        }
    }

    const handleSignup = () => {
        if (email.length === 0) {
            setEmailError('Please fill out the field');
        } else {
            setStep(1);
        }
    }

    return (
        <div className="relative h-[100vh] bg-[#090B17] text-white">
            <img src="/img/auth/topLeft.png" alt="topLeft" className="absolute top-[0] left-[-20px]"/>
            <img src="/img/auth/topRight.png" alt="topRight" className="absolute top-0 right-0"/>
            <img src="/img/auth/bottomLeft.png" alt="bottomLeft" className="absolute bottom-0 left-0"/>
            <img src="/img/auth/bottomRight.png" alt="bottomRight" className="absolute bottom-0 right-0"/>
            <div className="max-w-[400px] h-[100%] mx-auto py-[54px] flex flex-col">
                <Link to="/">
                    <div className="flex justify-center">
                        <img src="/img/logo.svg" alt="logo" className="mr-2"/>
                        <span className="text-[28px]">CHAKODY</span>
                    </div>
                </Link>
                {
                    step === 0 &&
                    <div className="h-[100%] flex items-center">
                        <div className="w-[100%]">
                            <h4 className="text-[28px] py-4">Sign Up</h4>
                            <div className="flex justify-between">
                                <div className="flex flex-col w-[45%]">
                                    <label className="py-2">First Name</label>
                                    <div className="relative">
                                        <input className="w-[100%] border-[1px] border-[#636267] rounded-full bg-[#090B17] outline-none px-8 py-3 focus:border-[#644FE5]"/>
                                    </div>
                                </div>
                                <div className="flex flex-col w-[45%]">
                                    <label className="py-2">Last Name</label>
                                    <div className="relative">
                                        <input className="w-[100%] border-[1px] border-[#636267] rounded-full bg-[#090B17] outline-none px-8 py-3 focus:border-[#644FE5]"/>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="flex flex-col">
                                <label className="py-2">Email</label>
                                <div className="relative">
                                    <input className="w-[100%] border-[1px] border-[#636267] rounded-full bg-[#090B17] outline-none px-8 py-3 focus:border-[#644FE5]" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className={`absolute right-[20px] top-[20px] cursor-pointer ${email.length ? "block" : "hidden"}`} onClick={() => setEmail('')}>
                                        <path d="M2 13.0007L12.8894 2.11133" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M2 2L12.8894 12.8894" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <div className={`flex mt-2 ${(emailError.length && !email.length) ? "block" : "hidden"}`}>
                                        <img src="/img/auth/warning-200.svg" alt="warning"/>
                                        <p className="text-[#F6475D] px-2">{emailError}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col mt-4">
                                <label className="py-2">Password</label>
                                <div className="relative">
                                    <input className="border-[1px] border-[#636267] rounded-full bg-[#090B17] outline-none px-8 py-3 w-[100%] focus:border-[#644FE5]" type={passwordType} />
                                    <img src="/img/auth/eye.svg" alt="show" className="absolute right-[20px] bottom-[13px] cursor-pointer" onClick={togglePasswordType}/>
                                </div>
                            </div>
                            <div className="flex mt-8 my-4">
                                <input type="checkbox" className="mr-4 bg-[#9D9DFF]"/>
                                <p className="text-[#A9A9A9]">I certify that I am 18 years old of age or older, and agree to the User Agreement and Privacy Policy.</p>
                            </div>
                            <button className="bg-[#644FE5] w-[100%] px-8 py-4 rounded-full my-4" onClick={handleSignup}>Create Account</button>
                            <div className="text-center my-4 py-2">Do you have an account? <Link to="/signin"><span className="text-[#9D9DFF]">Sign In</span></Link></div>
                        </div>
                    </div>
                }
                {
                    step === 1 &&
                    <div className="h-[100%] mt-[150px]">
                        <h4 className="text-[28px]">Select your citizenship</h4>
                        <p className="text-[#A9A9A9]">Chakody is <span className="text-[#9D9DFF]">legally required</span> to collect this information. If you’re a citizen of more than one country, please pick one.</p>
                        <div className="flex flex-col mt-4">
                            <label className="py-2">Country</label>
                            <ReactFlagsSelect
                                searchable 
                                selected={selectedCountry}
                                onSelect={(code) => setSelectedCountry(code)}
                                className="country-select"
                            />
                        </div>
                        <button className="bg-[#644FE5] w-[100%] px-8 py-4 rounded-full my-4" onClick={() => setStep(2)}>Submit</button>
                    </div>
                }
                {
                    step === 2 &&
                    <div className="h-[100%] mt-[150px]">
                        <h4 className="text-[28px]">Enter your date of birth</h4>
                        <p className="text-[#A9A9A9] my-4 pr-8">Your age information will be updated on your profile page and this will displayed on your dashboard.</p>
                        <div className="flex flex-col mt-4">
                            <label className="py-2">Date of birth</label>
                            <div className="flex justify-between">
                                <div className="rounded-full bg-[#090B17] border-[1px] border-[#636267] w-[100px]">
                                    <select className="px-4 py-2 bg-[#090B17] rounded-full outline-none">
                                        <option>MM</option>
                                        <option>01</option>
                                        <option>02</option>
                                        <option>03</option>
                                        <option>04</option>
                                        <option>05</option>
                                        <option>06</option>
                                        <option>07</option>
                                        <option>08</option>
                                        <option>09</option>
                                    </select>
                                </div>
                                <div className="rounded-full bg-[#090B17] border-[1px] border-[#636267] w-[100px]">
                                    <select className="px-4 py-2 bg-[#090B17] rounded-full outline-none">
                                        <option>DD</option>
                                        <option>01</option>
                                        <option>02</option>
                                        <option>03</option>
                                        <option>04</option>
                                        <option>05</option>
                                        <option>06</option>
                                        <option>07</option>
                                        <option>08</option>
                                        <option>09</option>
                                    </select>
                                </div>
                                <div className="rounded-full bg-[#090B17] border-[1px] border-[#636267] w-[120px]">
                                    <select className="px-5 py-2 bg-[#090B17] rounded-full outline-none">
                                        <option>YYYY</option>
                                        <option>1990</option>
                                        <option>1991</option>
                                        <option>1992</option>
                                        <option>1993</option>
                                        <option>1994</option>
                                        <option>1995</option>
                                        <option>1996</option>
                                        <option>1997</option>
                                        <option>1998</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <button className="bg-[#644FE5] w-[100%] px-8 py-3 rounded-full my-4 mt-10 opacity-50" onClick={() => setStep(3)}>Submit</button>
                    </div>
                }
                {
                    step === 3 &&
                    <div className="h-[100%] mt-[150px]">
                        <h4 className="text-[28px]">Enter your phone number</h4>
                        <p className="text-[#A9A9A9] py-8">We’ll send you a code to secure your account.</p>
                        <label>Phone number</label>
                        <div className="flex mt-4">
                            <CountrySelector
                                selectedCountry={phoneInput.country.iso2}
                                onSelect={(country) => phoneInput.setCountry(country.iso2)}
                                renderButtonWrapper={({ children, rootProps }) => {
                                    return (
                                      <button
                                        {...rootProps}
                                        className="px-4 py-2 rounded-full border-[1px] border-[#636267] flex flex-row items-center w-[120px]"
                                      >
                                        {children.props.children[0]} {/* Country Flag */}
                                        <span className="mx-2">{phoneInput.country.name.slice(0, 3)}</span> {/* Truncated country name */}
                                        {children.props.children[1]} {/* Rest of the children, like an arrow icon */}
                                      </button>
                                    );
                                  }}
                                  dropdownStyleProps={{
                                    style: {
                                      top: "35px",
                                    },
                                  }}
                            />
                            <input
                                placeholder="000 0000000"
                                type="tel"
                                name="phone"
                                className="w-[100%] ml-8 px-4 py-2 rounded-full border-[1px] border-[#636267] outline-none bg-[#090B17]"
                            />
                        </div>
                        
                        <button className="bg-[#644FE5] w-[100%] px-8 py-3 rounded-full my-4 flex justify-center items-center mt-10">
                            Continue
                        </button>
                    </div>
                }                
                <div className="mb-2">
                    <ul className="flex justify-between">
                        <li>
                            <div className="flex">
                                <img src="/img/icon1.svg" alt="language" className="mr-2"/>
                                <p>English</p>
                            </div>
                        </li>
                        <li>Cookies</li>
                        <li>Terms</li>
                        <li>Privacy</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}