import React from "react";

import SecurityItem from "./SecurityItem";

const data = [
    {
        title: "Passkeys",
        description: "Protect your account and withdrawals with Passkeys and/or security keys, such as Yubikey.",
        actionLabel: "Enable"
    },
    {
        title: "Authenticator app",
        description: "Use Chakody/Google Authenticator to protect your account and transactions.",
        actionLabel: "Manage"
    },
    {
        title: "Email",
        description: "Use your email to protect your account and transactions.",
        actionLabel: "Manage"
    },
    {
        title: "Phone number",
        description: "Use your phone number to protect your account and transactions.",
        actionLabel: "Manage"
    },
    {
        title: "Login password",
        description: "Login password is used to log in to your account.",
        actionLabel: "Manage"
    }
]

export default function SecuritySetting() {
    return (
        <div>
            <h4 className="py-4 mb-4">Security</h4>
            <div>
                <p>Two-factor authentication (2FA)</p>
                <div className="flex items-center">
                    <img src="/img/setting/alarm.png" alt=""/>
                    <div className="p-2">
                        <p className="text-[#A9A9A9] text-[13px]">To increase your account security, it is recommended that you enable 2FA, including Chakody/Google authenticator.</p>
                        <p className="text-[#9D9DFF] text-[13px]">Enable Chakody/Google authenticator now.</p>
                    </div>
                </div>
            </div>
            {
                data.map((item, index) => (
                    <SecurityItem title={item.title} description={item.description} actionLabel={item.actionLabel}/>
                ))
            }
            <h5 className="mt-12">Advanced security</h5>
            <SecurityItem 
                title="Account connections" 
                description="Use a third-party account, such as your Apple ID or Google account to log in to your Chakody account." 
                actionLabel="Manage"
            />
            <h5 className="mt-8">Crypto withdrawal</h5>
            <SecurityItem 
                title="One-step withdrawal" 
                description="When this function is turned on, you can withdraw small amount crypto to whitelisted addresses without passing 2FA verification" 
                actionLabel="Enable"
            />
            <SecurityItem 
                title="Withdrawal whitelist" 
                description="Once this function is enabled, your account will only be able to withdraw to addresses on your whitelist." 
                actionLabel="Enable"
            />
            <h5 className="mt-8">Account management</h5>
            <SecurityItem 
                title="Disable account" 
                description="Once the account is disabled, most of your actions will be restricted, such as logging in and trading. You can choose to unlock the account at any time." 
                actionLabel="Disable"
            />
            <SecurityItem 
                title="Delete account" 
                description="Please note that account deletion is irreversible. Once deleted, you will not be able to access your account or view your transaction history." 
                actionLabel="Delete"
            />
        </div>
    )
}