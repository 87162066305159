import React from "react";

export default function MyReferral({handleToggleModal}) {
    return (
        <div className="text-center mt-20">
            <h3 className="text-[48px]">My referrals</h3>
            <ul className="text-start max-w-[1000px] mx-auto mt-12 pl-10">
                <li className="my-2">1.Verification and trade status will be updated within 1 hour. Stay tuned!</li>
                <li className="my-2">2.If any of the users you invited are labeled as risky, your eligibility to receive rewards might be affected.</li>
            </ul>
            <div className="max-w-[1000px] mx-auto flex bg-[#151623] rounded-[20px] p-8 mb-6 text-[#A9A9A9] mt-10">
                <table className="w-[100%]">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Registration Date</th>
                            <th>Verification</th>
                            <th>Deposit</th>
                            <th>Trade</th>
                        </tr>
                    </thead>
                    <tbody>
                        <td colSpan={5}>
                            <div className="flex justify-center items-center flex-col h-[200px] max-w-[200px] mx-auto mt-[50px]">
                                <img src="/img/referral/lock.png" alt="lock"/>
                                <p>Your account has not have any rewards yet</p>
                            </div>
                        </td>
                    </tbody>
                </table>
            </div>
            <button className="mt-10 px-40 py-4 bg-gradient-to-r from-[#644FE5] to-[#4645C2] rounded-full" onClick={handleToggleModal}>Invite Friends</button>
        </div>
    )
}