import React from "react";

import Intro from "../components/fintech/Intro";
import Web3Exp from "../components/fintech/Web3Exp";
import Web3Investment from "../components/fintech/Web3Investment";
import UserNumber from "../components/fintech/UserNumber";
import GetStarted from "../components/fintech/GetStarted";
import Plan from "../components/fintech/Plan";
import Footer from "../components/Footer";
import NavBarLatest from "../components/NavBarLatest";

export default function Fintech() {
    return (
        <div className="bg-[#090B17] text-white font-sans">
            <NavBarLatest/>
            <Intro/>
            <Web3Exp/>
            <Web3Investment/>
            <GetStarted/>
            <UserNumber/>
            <Plan/>
            <Footer/>
        </div>
    )
}