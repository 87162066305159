import React from "react";
import { Link } from "react-router-dom";

const data = [
    {
        icon: "/img/buy/icon3.png",
        description: "Investment Plans",
        url: "/",
        details: "Multiple Investment plans for Short term and long term options",
        action: "Explore"
    },
    {
        icon: "/img/buy/icon2.png",
        description: "Hold & Earn",
        url: "/",
        details: "Hold Fiat currency or Stake crypto in your wallet and earn",
        action: "Explore"
    },
    {
        icon: "/img/buy/icon1.png",
        description: "Flexible Wallet",
        url: "/",
        details: "Experience flexibility to hold Fiat currency and Cryptocurrency in a single account",
        action: "Explore"
    },
]

export default function LandingSecure() {
    return (
        <div className="text-center mt-28">
            <h2 className="text-[48px]">Passive Pools</h2>
            <p className="text-[23px] text-[#CCCCCC]">Your Neobank for save and earn. Easy to use and Secure.</p>
            <div className="flex mx-[56px]">
                <div className="flex mt-20 flex-wrap md:flex-nowrap flex-col md:flex-row w-[100%]">
                    {data.map(item => (
                        <div key={item.description} className="text-center card-background mx-3 md:w-[33%] rounded-lg mt-10">
                            <img src={item.icon} alt="icon" className="mx-auto mt-[-60px]"/>
                            <h4 className="mx-auto text-[28px] min-h-20 max-w-[300px]">{item.description}</h4>
                            <p className="px-16 mb-12">{item.details}</p>
                            {/* <button className="my-12 get-started-btn mt-0 w-[150px]">
                                <Link to={item.url} className="flex justify-center">
                                    {item.action}
                                </Link>
                            </button> */}
                        </div>
                    ))}
                </div>
            </div>
            <button className="my-12 mb-0 get-started-btn w-[150px]">
                <Link to="/invest" className="flex justify-center">
                    Explore
                </Link>
            </button>
        </div>
    )
}