import React from "react";

const data = [
    '1.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt .',
    '2.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt .sed do eiusmod tempor incididunt ',
    '3.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt .sed do eiusmod tempor incididunt ',
    '4.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt .sed do eiusmod tempor incididunt ',
    '5.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt .sed do eiusmod tempor incididunt2.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt .sed do eiusmod tempor incididunt  '
]

export default function ReferralRules() {
    return (
        <div className="text-center max-w-[1000px] mx-auto mt-32">
            <h3 className="text-[48px] mb-8">Rules</h3>
            <ul className="text-start mt-6 px-6">
                {data.map((item, index) => <li key={index} className="py-3">{item}</li>)}
            </ul>
        </div>
    )
}