import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Sigin() {

    const [step, setStep] = useState(0);

    const [email, setEmail] = useState('');
    const [passwordType, setPasswordType] = useState('password');

    const togglePasswordType = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
        } else {
            setPasswordType('password');
        }
    }

    const handleSubmit = () => {
        setStep(3); 
        setTimeout(function() {
            setStep(4);
        }, 3000);
    }

    return (
        <div className="relative h-[100vh] bg-[#090B17] text-white">
            <img src="/img/auth/topLeft.png" alt="topLeft" className="absolute top-[0] left-[-20px]"/>
            <img src="/img/auth/topRight.png" alt="topRight" className="absolute top-0 right-0"/>
            <img src="/img/auth/bottomLeft.png" alt="bottomLeft" className="absolute bottom-0 left-0"/>
            <img src="/img/auth/bottomRight.png" alt="bottomRight" className="absolute bottom-0 right-0"/>
            <div className="max-w-[400px] h-[100%] mx-auto py-[54px] flex flex-col">                
                <Link to="/">
                    <div className="flex justify-center">
                        <img src="/img/logo.svg" alt="logo" className="mr-2"/>
                        <span className="text-[28px]">CHAKODY</span>
                    </div>
                </Link>                    
                {
                    step === 0 &&
                    <div className="h-[100%] flex items-center">
                        <div className="w-[100%]">
                            <h4 className="text-[28px] py-4">Sign In</h4>
                            <div className="flex flex-col">
                                <label className="py-2">Email/Phone number</label>
                                <div className="relative">
                                    <input className="w-[100%] border-[1px] border-[#636267] rounded-full bg-[#090B17] outline-none px-8 py-4" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className={`absolute right-[20px] bottom-[20px] cursor-pointer ${email.length ? "block" : "hidden"}`} onClick={() => setEmail('')}>
                                        <path d="M2 13.0007L12.8894 2.11133" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M2 2L12.8894 12.8894" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                            <button className="bg-[#644FE5] w-[100%] px-8 py-4 rounded-full my-4" onClick={() => setStep(1)}>Next</button>
                            <div className="flex justify-between items-center my-8">
                                <div className="w-[170px] h-[1px] bg-[#636267]"/>
                                <p>or</p>
                                <div className="w-[170px] h-[1px] bg-[#636267]"/>
                            </div>
                            <button className="w-[100%] px-8 py-4 border-[1px] border-[#636267] rounded-full my-4 flex justify-center">
                                <img src="/img/auth/google.png" alt="google login" className="mr-8"/>
                                <p>Continue with Google</p>
                            </button>
                            <button className="w-[100%] px-8 py-4 border-[1px] border-[#636267] rounded-full my-4 flex justify-center">
                                <img src="/img/auth/apple.png" alt="apple login" className="mr-8"/>
                                <p>Continue with Apple</p>
                            </button>
                            <div className="text-center my-4 py-2">Don’t have an account yet? <Link to="/signup"><span className="text-[#9D9DFF]">Sign Up</span></Link></div>
                        </div>
                    </div>
                }
                {
                    step === 1 &&
                    <div className="h-[100%] mt-[150px]">
                        <h4 className="text-[28px]">Enter your password</h4>
                        <p className="text-[#A9A9A9]">loremipsum@gmail.com</p>
                        <div className="flex flex-col mt-4">
                            <label className="py-2">Password</label>
                            <div className="relative">
                                <input className="border-[1px] border-[#636267] rounded-full bg-[#090B17] outline-none px-8 py-3 w-[100%]" type={passwordType} />
                                <img src="/img/auth/eye.svg" alt="show password" className="absolute right-[20px] bottom-[13px] cursor-pointer" onClick={togglePasswordType}/>
                            </div>
                        </div>
                        <p className="text-[#9D9DFF] py-4">Forgot password?</p>
                        <button className="bg-[#644FE5] w-[100%] px-8 py-4 rounded-full my-4" onClick={() => setStep(2)}>Next</button>
                    </div>
                }
                {
                    (step === 2 || step === 3) &&
                    <div className="h-[100%] mt-[150px]">
                        <h4 className="text-[28px]">Verification code</h4>
                        <p className="text-[#A9A9A9]">Please enter a verification code we have sent to lore***@gmail.com</p>
                        <input className="border-[1px] border-[#636267] rounded-full bg-[#090B17] outline-none px-8 py-3 w-[100%] my-4" />
                        <p className="text-[#9D9DFF] mb-4">Didn’t get the code?</p>
                        <button className="bg-[#644FE5] w-[100%] px-8 py-4 rounded-full my-4 flex justify-center items-center" onClick={handleSubmit}>
                            { step === 3 && <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white mr-2"></div>}
                            <p>Submit</p>
                        </button>
                    </div>
                }
                {
                    step === 4 &&
                    <div className="h-[100%] mt-[150px]">
                        <h4 className="text-[28px]">Trust this device</h4>
                        <p className="text-[#A9A9A9] py-8">By clicking ‘Yes’, you can stay logged in for up to 5 days on this device. To revoke your logged in status, log out of your Chakody account on this device.</p>
                        <button className="bg-[#644FE5] w-[100%] px-8 py-4 rounded-full my-4 flex justify-center items-center">
                            Yes
                        </button>
                        <button className="border-[1px] border-[#644FE5] w-[100%] px-8 py-4 rounded-full my-4 flex justify-center items-center hover:bg-[#644FE5]">
                            Not now
                        </button>
                    </div>
                }                
                <div className="mb-2">
                    <ul className="flex justify-between">
                        <li>
                            <div className="flex">
                                <img src="/img/icon1.svg" alt="language" className="mr-2"/>
                                <p>English</p>
                            </div>
                        </li>
                        <li>Cookies</li>
                        <li>Terms</li>
                        <li>Privacy</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}