import React from "react";

import ReferralNav from "../components/referral/ReferralNav";
import SettingSideBar from "../components/setting/SettingSideBar";
import { Outlet } from "react-router-dom";

export default function Setting() {
    return (
        <div className="bg-[#090B17] text-white py-4">
            <ReferralNav/>
            <div className="mx-[56px]">
                <div className="flex flex-row">
                    <SettingSideBar/>
                    <div className="bg-[#24263D] rounded-lg w-[100%] my-4 p-4 px-10" style={{height: 'fit-content'}}>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </div>
    )
}