import React from "react";

// import NavBarWithAuth from "../components/NavBarWithAuth";
import NavBarLatest from "../components/NavBarLatest";
import NotifyPage from "../components/common/NotifyPage";

export default function UnderConstruction() {
    return (
        <div className="bg-[#090B17] h-screen text-white relative background-with-blur font-sans">
            <NavBarLatest/>
            <div className="flex flex-col justify-center text-center">
                <h2 className="text-[48px] md:text-[91px] mt-8 md:mt-24 mb-4 font-[500]">Under Construction</h2>
                <p className="text-[19px] mb-3">We are building this page and will be available Very Soon.</p>
                <p className="text-[19px] mb-3">Stay Tuned.</p>
                <div className="mt-6">
                    <NotifyPage productName={'Invest 2 Earn'} />
                </div>                
            </div>
            <div className="flex justify-between">
                <img src="/img/decorate1.png" alt="decoration" className="mx-[15%] mt-8 absolute"/>
                <img src="/img/decorate2.png" alt="decoration" className="mx-[15%] mt-8 absolute right-0"/>
            </div>
        </div>
    )
}