import React from "react";
import { Link } from "react-router-dom";
import {
      ClockIcon, CalendarDaysIcon, CalendarIcon,
      CalendarDateRangeIcon, TrophyIcon
    } from '@heroicons/react/24/outline'

export default function LandingIntro() {
    return (
        <div className="mx-[56px] mt-8 flex flex-col lg:flex-row">
            <div className="w-[50%]">
                <p className="text-[32px] md:text-[48px]">Play, Invest & Earn</p>                
                <p>Gaming, Investing, NFTs, and more..</p>
                <p>Join us to create your new Earning career </p>
                <div className="relative">
                    <img src="/img/buy/intro.png" alt="intro" className="ml-[-56px]"/>
                    <Link to="/signin">
                        <button className="connect-wallet-btn absolute top-0">Get Started</button>
                    </Link>
                </div>
            </div>            
            <div className="w-[100%] lg:w-[50%]">                
                <div className="flex">
                    <div className="bg-[#24263D] rounded-[20px] px-8 py-2 w-[50%] m-2">
                        <div className="text-[24px] md:text-[24px]">Refer and Earn</div>
                        <div className="text-[#CCCCCC] text-[19px]">Details available soon..</div>
                    </div>
                    <div className="bg-[#24263D] rounded-[20px] px-8 py-2 w-[50%] m-2">
                        <div className="text-[24px] md:text-[24px]">ODG</div>
                        <div className="text-[#CCCCCC] text-[19px]">Gaming Platform</div>
                    </div>
                </div>
                <div className="bg-[#24263D] rounded-[20px] px-8 py-2 m-2">
                    <p className="text-center m-4 pb-4 text-[24px] md:text-[24px]">Crypto Paper Trading Contests</p>                                       
                    <div className="grid grid-cols-2 mb-8 items-center">
                        <div className="flex flexbox items-center">
                            <ClockIcon className="h-14 w-14 text-purple-500" aria-hidden="true" />
                            <span className="text-lg mx-4">Daily Contests</span>
                        </div>
                        <div className="flex flexbox items-center">
                            <TrophyIcon className="h-12 w-12 text-yellow-100" aria-hidden="true" />
                            <label className="text-lg mx-4">100 USDT</label>     
                        </div>                     
                    </div>
                    <div className="grid grid-cols-2 mb-8 items-center">
                        <div className="flex flexbox items-center">
                            <CalendarDateRangeIcon className="h-14 w-14 text-yellow-500" aria-hidden="true" />
                            <span className="text-lg mx-4">Weekly Contests</span>
                        </div>
                        <div className="flex flexbox items-center">
                            <TrophyIcon className="h-12 w-12 text-yellow-100" aria-hidden="true" />
                            <label className="text-lg mx-4">1,000 USDT</label>     
                        </div>                     
                    </div>
                    <div className="grid grid-cols-2 mb-8 items-center">
                        <div className="flex flexbox items-center">
                            <CalendarDaysIcon className="h-14 w-14 text-orange-500" aria-hidden="true" />
                            <span className="text-lg mx-4">Monthly Contests</span>
                        </div>
                        <div className="flex flexbox items-center">
                            <TrophyIcon className="h-12 w-12 text-yellow-100" aria-hidden="true" />
                            <label className="text-lg mx-4">10,000 USDT</label>     
                        </div>                     
                    </div>
                    <div className="grid grid-cols-2 mb-8 items-center">
                        <div className="flex flexbox items-center">
                            <CalendarIcon className="h-14 w-14 text-green-500" aria-hidden="true" />
                            <span className="text-lg mx-4">The Big League</span>
                        </div>
                        <div className="flex flexbox items-center">
                            <TrophyIcon className="h-12 w-12 text-yellow-100" aria-hidden="true" />
                            <label className="text-lg mx-4">100,000 USDT</label>     
                        </div>                     
                    </div>                    
                    <div>
                        <Link to="/">
                            <button className="connect-wallet-btn mb-6">Explore Contests</button>
                        </Link>
                    </div>                    
                    <p className="mb-6">
                        What is Paper Trading?
                        <p>
                            <small>
                                Paper trading is the process of trading market assets with virtual or dummy money. This type of trading is generally used for practice and there is no lose of real money.
                            </small>
                        </p>
                    </p>                    
                </div>               
                <div className="bg-[#24263D] rounded-[20px] p-8 m-2">                    
                    <p className="text-[19px] mb-4">Announcing our First Crypto Contest Soon.....</p>                    
                    <p>Follow Us on Social Platforms for updates</p>
                    <p>
                        <a href="https://www.linkedin.com/company/chakodytech" target="_blank">
                            <img src="/img/referral/linkedin.png" alt="notification" className="px-4"/>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    )
}