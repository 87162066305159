import React from "react";
import { Link } from "react-router-dom";

const data = [
    {
        icon: "/img/fintech/icon1.png",
        description: "Invest in crypto",
        url: "/",
        action: "Invest"
    },
    {
        icon: "/img/fintech/icon2.png",
        description: "Fast transaction",
        url: "/",
        action: "Explore"
    },
    {
        icon: "/img/fintech/icon3.png",
        description: "100% Secure",
        url: "/",
        action: "Connect"
    },
]

export default function GetStarted() {
    return (
        <div className="text-center mt-36">
            <h2 className="text-[48px]">You do the business <br/>we’ll handle the money</h2>
            <p className="text-[23px] text-[#CCCCCC]">With the fintech product, you can improve your financial life by investing <br/>& earning, saving your money secure and private.</p>
            <div className="flex mx-[56px]">
                <div className="flex mt-36 flex-wrap md:flex-nowrap flex-col md:flex-row w-[100%]">
                    {data.map(item => (
                        <div key={item.title} className="text-center card-background mx-3 md:w-[33%] rounded-lg mt-10">
                            <img src={item.icon} alt="icon" className="mx-auto mt-[-60px]"/>
                            <h4 className="mx-auto text-[28px] min-h-20 max-w-[300px]">{item.description}</h4>
                            <button className="my-12 get-started-btn mt-0 w-[150px]">
                                <Link to={item.url} className="flex justify-center">
                                    {item.action}
                                </Link>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}