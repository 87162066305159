import React from "react";

export default function SecurityItem({title, description, actionLabel}) {
    return (
        <div className="flex justify-between items-center">
            <div className="py-2">
                <h5 className="py-1 text-[13px]">{title}</h5>
                <p className="text-[13px] text-[#A9A9A9] py-1">{description}</p>
            </div>
            <button className="border-2 border-[#644FE5] rounded-full px-6 py-1 w-[110px]">{actionLabel}</button>
        </div>
    )
}