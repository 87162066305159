import React, {useState} from "react";

import Modal from "../common/Modal";
import ToggleSwitch from "../common/ToggleSwitch";

const languages = [
    "English",
    "Русский",
    "Español(Internacional)",
    "Türkçe",
    "Français",
    "Português",
    "日本語"
]

export default function NotificationSetting() {

    const [isToggledActivity, setIsToggledActivity] = useState(true);
    const [isToggledNoti, setIsToggledNoti] = useState(true);
    const [isToggledNews, setIsToggledNews] = useState(true);
    const [isToggledSystem, setIsToggledSystem] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const [lang, setLang] = useState('');

    const handleToggleActivity = () => {
        setIsToggledActivity(!isToggledActivity);
    };

    const handleToggleNoti = () => {
        setIsToggledNoti(!isToggledNoti);
    };

    const handleToggleNews = () => {
        setIsToggledNews(!isToggledNews);
    };

    const handleToggleSystem = () => {
        setIsToggledSystem(!isToggledSystem);
    };

    return (
        <div>
            <h4 className="text-[19px] mt-4">Notifications</h4>
            <div className="flex justify-between items-center flex-wrap">
                <div className="my-8">
                    <h5 className="py-2">Notification language</h5>
                    <p className="text-[13px] py-2 text-[#A9A9A9]">This will affect the language settings of E-mail</p>
                </div>
                <div>
                    <button className="border-2 border-[#644FE5] w-[126px] py-2 rounded-full mx-4 hover:bg-[#644FE5]">Default</button>
                    <button className="border-2 border-[#644FE5] w-[126px] py-2 rounded-full bg-[#644FE5]" onClick={toggleModal}>Edit</button>
                </div>
            </div>
            <div>
                <h5 className="py-2">Notification preferences</h5>
                <p className="text-[13px] py-2 text-[#A9A9A9]">Once configured, you will receive relevant on-site inbox notifications</p>
            </div>
            <div className="flex py-6">
                <ToggleSwitch enabled={isToggledActivity} onChange={handleToggleActivity} />
                <p className="px-12">Activities</p>
            </div>
            <div className="flex py-6">
                <ToggleSwitch enabled={isToggledNoti} onChange={handleToggleNoti} />
                <p className="px-12">Trade notification</p>
            </div>
            <div className="flex py-6">
                <ToggleSwitch enabled={isToggledNews} onChange={handleToggleNews} />
                <p className="px-12">Chakody news</p>
            </div>
            <div className="flex py-6">
                <ToggleSwitch enabled={isToggledSystem} onChange={handleToggleSystem} />
                <p className="px-12">System messages</p>
            </div>
            <Modal isOpen={isModalOpen} toggleModal={toggleModal}>
                <h2 className="text-[23px] font-semibold text-start ml-8">Notification language</h2>
                <ul className="max-h-[270px] ml-8 mt-8 overflow-y-auto" style={{scrollbarWidth: 'none'}}>
                    {languages.map((item, index) => (
                        <li key={index} className="text-start flex items-center my-4 cursor-pointer" onClick={() => setLang(item)}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill={`${item === lang ? "#644FE5" : "none"}`} xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.35" y="0.35" width="13.3" height="13.3" rx="6.65" stroke="#A9A9A9" stroke-width="0.7"/>
                            </svg>
                            <p className="mx-4">{item}</p>
                        </li>
                    ))}
                </ul>
                <div className="bg-[#24263D]">
                    <button className="border-2 border-[#644FE5] w-[126px] py-2 rounded-full mx-4 hover:bg-[#644FE5]" onClick={() => toggleModal()}>Default</button>
                    <button className="border-2 border-[#644FE5] w-[126px] py-2 rounded-full bg-[#644FE5]" onClick={() => toggleModal()}>Confirm</button>
                </div>
            </Modal>
        </div>
    )
}