import React from "react";

export default function ReferralReward({handleToggleModal}) {
    return (
        <div className="text-center max-w-[1000px] mx-auto">
            <h3 className="text-[48px] my-20">Available rewards</h3>
            <div className="flex bg-[#151623] rounded-[20px] p-8 mb-6 flex-col md:flex-row">
                <div className="text-start md:w-[66%]">
                    <h5 className="text-[23px] font-bold">0.003 BTC Token Vouchers</h5>
                    <ul className="mt-8">
                        <li className="my-2">1.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt .</li>
                        <li className="my-2">2.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt .</li>
                    </ul>
                </div>
                <div className="md:w-[33%] flex items-center justify-end">
                    <button className="bg-[#644FE5] rounded-full px-20 py-3 mr-4" onClick={handleToggleModal}>Invite</button>
                </div>
            </div>
            <div className="flex bg-[#151623] rounded-[20px] p-8 mb-6 flex-col md:flex-row">
                <div className="text-start md:w-[66%]">
                    <h5 className="text-[23px] font-bold">100 USDT Trading Fee Rebate Voucher</h5>
                    <ul className="mt-8">
                        <li className="my-2">1.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt .</li>
                    </ul>
                </div>
                <div className="md:w-[33%] flex items-center justify-end">
                    <button className="bg-[#644FE5] rounded-full px-20 py-3 mr-4" onClick={handleToggleModal}>Invite</button>
                </div>
            </div>
        </div>
    )
}