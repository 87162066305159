import React from "react";

const data = [
    {
        value: "8.6K",
        title: "Wallets connected"
    },
    {
        value: "116K",
        title: "Transaction processed"
    },
    {
        value: "$3M+",
        title: "Safely transferred"
    },
    {
        value: "700+",
        title: "Coins & tokens"
    },
    {
        value: "16+",
        title: "Blockchains"
    },
    {
        value: "50+",
        title: "Countries"
    }
]

export default function UserNumber() {
    return (
        <div className="flex justify-center card-background-1 trusted-wallet-card mt-56 relative">
            <div className="w-[100%] text-center">
                <h2 className="text-[48px] py-20">The most trusted wallet solution</h2>
                <div className="flex flex-wrap mb-20">
                    {data.map(item => (
                        <div className="w-[33.33%]">
                            <h2 className="text-[48px] mt-6">{item.value}</h2>
                            <p className="text-[16px] text-[#999999] my-6">{item.title}</p>        
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}