import React, { useState } from "react";

import { Link } from "react-router-dom";
import SettingDropDown from "../setting/SettingDropDown";

export default function ReferralNav() {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    return (
        <div className="px-[56px] py-[54px] flex justify-between flex-col md:flex-row">
            <div className="flex flex-wrap">
                <div className="flex">
                    <img src="/img/logo.svg" alt="logo" className="mr-2"/>
                    <span className="text-[28px]">CHAKODY</span>
                </div>
                <div className="flex ml-6 relative">
                    <button className="px-8">
                        <img src="/img/referral/dashboardIcon.png" alt="dashboard"/>
                    </button>
                    <div className="bg-[#24263D] rounded-full flex items-center">
                        <img src="/img/referral/searchIcon.svg" alt="search" className="pl-6"/>
                        <input placeholder="Search in dashboard..." className="bg-[#24263D] rounded-full pl-4 outline-none 2xl:w-[300px]"/>
                    </div>
                </div>
            </div>
            <div className="flex hidden md:flex">
                <div className="relative" id="dropdown">
                    <button className="px-4 py-2 text-white bg-[#24263D] rounded-full flex items-center">
                        <img src="/img/icon1.svg" alt="" className="mx-2"/>
                        English 
                        <img src="/img/referral/arrow-down.svg" alt="" className="mx-2"/>
                    </button>
                </div>
                <button className="bg-[#24263D] rounded-full mx-2">
                    <img src="/img/referral/bag.png" alt="wallet" className="px-4"/>
                </button>
                <button className="bg-[#24263D] rounded-full mx-2">
                    <Link to="/">
                        <img src="/img/referral/bell.png" alt="notification" className="px-4"/>
                    </Link>
                </button>
                <button className="bg-[#24263D] rounded-full mx-2 relative" onClick={() => setDropdownOpen(!dropdownOpen)}>
                    <img src="/img/referral/settingIcon.png" alt="setting" className="px-4"/>
                    {dropdownOpen && <SettingDropDown/>}
                </button>
            </div>
        </div>
    )
}