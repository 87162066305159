import React from "react";

export default function Intro() {
    return (
        <div className="flex justify-between mx-4 md:mx-[2px] overflow-hidden background-with-blur">
            <div className="md:mx-[42px]">
                <div className="text-[32px] md:text-[45px] mt-8">Invest and Earn With our</div>
                <div className="text-[32px] md:text-[45px]"><span className="text-gradient">FinTech</span> Products</div>
                <p className="text-[#A9A9A9] text-[18px] mt-6 max-w-[600px]">Chakody provides regulated custody, financial services, and core infrastructure for investors and builders alike.</p>
                <button className="connect-wallet-btn">Connect Wallet</button>
            </div>
            <div>
                <div className="flex">
                    <img src="/img/fintech/EOS_3D.png" alt="ether" className="h-[126px] mt-[600px]"/>
                    <img src="/img/fintech/ether.png" alt="ether" className=""/>
                    <img src="/img/fintech/Polygon_3D.png" alt="ether" className="h-[126px] mt-[100px]"/>
                </div>
            </div>
        </div>
    )
}