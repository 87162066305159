import React from "react";
import { Link } from "react-router-dom";

const data = [
    {
        icon: "/img/fintech/plan1.png",
        plan: "Free",
        description: "Perfect for developers, early-stage startups, and individuals",
        url: "/",
        action: "Invest",
        price: 0,
        price_p: "",
        comments: [
            "Basic access to fintech products",
            "Limited functionality",
            "Basic customer support via email"
        ],
        tips : [
            "Access to advanced analytics and reporting tools.",
            "Integration capabilities to streamline workflows and improve efficiency across systems",
            "Proactive customer and strategic advice",
            "Tailored solutions to meet the unique requirements of businesses and organizations",
            "API access for integrations with third-party systems and applications."
        ]
    },
    {
        icon: "/img/fintech/plan2.png",
        plan: "Team",
        description: "Advanced collaboration for individuals and organizations",
        url: "/",
        action: "Explore",
        price: 4,
        price_p: "per user / for the first 12 months",
        comments: [
            "Full access to all fintech products and features",
            "Collaboration tools for team projects, including shared workspaces and task management",
            "Priority customer support with faster response times",
            "Enhanced security features such as multi-factor authentication"
        ],
        tips: [
            "Access to advanced analytics and reporting tools.",
            "Integration capabilities to streamline workflows and improve efficiency across systems",
            "Proactive customer and strategic advice",
            "Tailored solutions to meet the unique requirements of businesses and organizations",
            "API access for integrations with third-party systems and applications."
        ]
    },
    {
        icon: "/img/fintech/plan3.png",
        plan: "Enterprise",
        description: "Security, compliance and flexible deployment",
        url: "/",
        action: "Connect",
        price: 19,
        price_p: "per user / for the first 12 months",
        comments: [
            "All features included in the Team Plan",
            "Advanced analytics and reporting for in-depth financial insights",
            "Customizable features tailored to specific business needs",
            "Dedicated account manager for personalized assistance and guidance",
            "API access for seamless integration with third-party systems and applications"
        ],
        tips: []
    },
]

export default function Plan() {
    return (
        <div className="text-center mt-36 market-plan relative">
            <h2 className="text-[48px] my-4">Choose your plan</h2>
            <p className="text-[23px] text-[#CCCCCC]">Use Chakody  with your whole team. Upgrade to enable<br/> unlimited issues, enhanced security, and additional features.</p>
            <div className="flex mx-[56px]">
                <div className="flex mt-36 flex-wrap md:flex-nowrap flex-col lg:flex-row w-[100%]">
                    {data.map(item => (
                        <div key={item.title} className="text-center price-card-background mx-3 lg:w-[33%] rounded-lg mt-10 hover:scale-105">
                            <img src={item.icon} alt="icon" className="mx-auto"/>
                            <h4 className="mx-auto text-[48px] min-h-20 max-w-[300px] my-6">{item.plan}</h4>
                            <p className="max-w-[320px] mx-auto">{item.description}</p>
                            <p className="flex leading-none my-10">
                                <div className="mx-auto flex">
                                    <div className="flex items-start text-[20px]">$</div>
                                    <span className="text-[80px]">0</span>
                                    <div className="flex items-end">/monthly</div>
                                </div>
                            </p>
                            <p className="mb-8">Forever</p>
                            <button className="my-12 get-started-btn mt-0 w-[150px]">
                                <Link to={item.url} className="flex justify-center">
                                    {item.action}
                                </Link>
                            </button>
                            <ul className="text-start list-disc p-12">
                                {item.comments.map(comment => (
                                    <li>{comment}</li>
                                ))}
                            </ul>
                            <ul className="text-start p-12 text-[#A9A9A9]">
                                {item.tips.map(tip => (
                                    <li>-{tip}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}