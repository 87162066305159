import React, { useState } from "react";

import ReferralNav from "../components/referral/ReferralNav";
import ReferralIntro from "../components/referral/ReferraIntro";
import ReferralReward from "../components/referral/ReferralReward";
import MyReferral from "../components/referral/MyReferral";
import MyReward from "../components/referral/MyReward";
import ReferralRules from "../components/referral/ReferralRules";
import Footer from "../components/Footer";

import Modal from "../components/common/Modal";

export default function Referral() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [shareModalOpen, setShareModalOpen] = useState(false);

    const toggleModal = () => setIsModalOpen(!isModalOpen);
    const toggleShareModal = () => setShareModalOpen(!shareModalOpen);

    return (
        <div className="bg-[#090B17] text-white">
            <ReferralNav/>
            <ReferralIntro/>
            <ReferralReward handleToggleModal={toggleModal}/>
            <MyReferral handleToggleModal={toggleShareModal}/>
            <MyReward/>
            <ReferralRules/>
            <Footer/>
            <Modal isOpen={isModalOpen} toggleModal={toggleModal}>
                <img src="/img/referral/Bitcoin_3D.png" alt="bitcoin" className="mx-auto"/>
                <h2 className="text-[23px] font-semibold">Invite friends to get FREE BTC</h2>
                <p className="mt-2 text-[#9D9DFF] mb-12">#ChakodyFreeBTC</p>
                <div className="flex bg-[#151623] rounded-full items-center justify-between my-4">
                    <input placeholder="Referral ID" className="text-[#A9A9A9] bg-[#151623] outline-none ml-8 py-4 rounded-full"/>
                    <div className="flex items-center mr-8">
                        <div className="px-2">CPA_NIUHUIKNJ</div>
                        <button>
                            <img src="/img/referral/copy.png" alt="copy" width="17px" height="18px"/>
                        </button>
                    </div>
                </div>
                <div className="flex bg-[#151623] rounded-full items-center justify-between my-4">
                    <input placeholder="Referral Link" className="text-[#A9A9A9] bg-[#151623] outline-none ml-8 py-4 rounded-full"/>
                    <div className="flex items-center mr-8">
                        <div className="px-2">http/bdbhsabk...</div>
                        <button>
                            <img src="/img/referral/copy.png" alt="copy" width="17px" height="18px"/>
                        </button>
                    </div>
                </div>
                <p className="text-start text-[#A9A9A9] my-6">Share via</p>
                <ul className="flex flex-row justify-between mb-6">
                    <li>
                        <a href="https://facebook.com">
                            <img src="/img/referral/facebook.png" alt="facebook"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com">
                            <img src="/img/referral/twitter.png" alt="twitter"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://whatsapp.com">
                            <img src="/img/referral/whatsapp.png" alt="whatsapp"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://instagram.com">
                            <img src="/img/referral/instagram.png" alt="instagram"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://linkedin.com">
                            <img src="/img/referral/linkedin.png" alt="likedin"/>
                        </a>
                    </li>
                </ul>
            </Modal>
            <Modal isOpen={shareModalOpen} toggleModal={toggleShareModal}>
                <img src="/img/referral/Bitcoin_3D.png" alt="bitcoin" className="mx-auto"/>
                <h2 className="text-[23px] font-bold">SHARE TO GET MORE</h2>
                <p className="mt-2 text-[#9D9DFF] mb-4 max-w-[400px] text-white mx-auto">Share it on your social media to get a <br/> <span className="text-[#9D9DFF]">0.003 BTC</span> boost now!</p>
                <button className="bg-[#644FE5] px-16 py-4 rounded-full my-4">Share</button>
            </Modal>
        </div>
    )
}